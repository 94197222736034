<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event"/>
      <v-card-title class="headline">Miro RunnerTag Integration for {{event.name}}</v-card-title>

      <v-card-text>      
        <p>
          Connect this event with a Miro RunnerTag event for automatic photo integration in the results.
        </p>

        <v-alert v-if="isConnectedWithMiro" type="success">
          <p>This event is connected with a Miro RunnerTag event.</p>
        </v-alert>

        <div v-if="!isConnectedWithMiro">
          <v-alert type="info">
            <p>This event is not yet connected with a Miro RunnerTag event.</p>
          </v-alert>

          <v-text-field 
            v-model="request.miro_event_id"
            label="Miro RunnerTag Event Id"
            hint="This is a number which shows in the Runnertag URL, e.g. 'https://dash.runnertag.com/#/events/YOUR_EVENT_ID/photos'"
            persistent-hint
            />

          <v-text-field 
            v-model="request.miro_event_url"
            label="RunnerTag Website url"
            hint="This looks like 'https://YOUR_EVENT_NAME.runnertag.site/'"
            persistent-hint
            />

          <v-text-field 
            v-model="request.miro_api_key"
            label="Miro RunnerTag API Key (optional)"
            hint="Optional API Key"
            persistent-hint
            />

          <v-btn :disabled="!request.miro_event_id" @click="connect">Connect</v-btn>
        </div>
      </v-card-text>

    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import Header from './_Header.vue'

export default {
  name: "IntegrationsMiro",
  components: {
    Header,
  },
  props: {
  },
  data() {
    return {
      event: null,
      siteData: siteData,
      request: {
        miro_event_id: null,
        miro_event_url: null,
        miro_api_key: null,
      }
    };
  },
  async mounted() {
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
  },
  methods: {
    async getEvent(id) {
      this.event = (await eventManagerService.get(id)).data;
    },

    async loadData() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

    async connect() {
      const response = (await eventManagerService.integrationsMiroConnect(this.event.id, this.request)).data;
      await this.loadData();
      this.$helpers.toastResponse(this, response, 'Successfully connected with selected event.');
    },


  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Integrations', exact: true, to: { name: 'eventmanagerIntegrations', params: {id: this.event.id}} },
        { text: `Miro Integration`, disabled: true },
      ];
    },
    isConnectedWithMiro() {
      return this.event && this.event.meta && this.event.meta.some(x => x.key == 'miro.e_id');
    },
    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

